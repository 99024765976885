import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { FaPlus, FaMinus, FaEdit, FaTrash, FaSave, FaBan, FaTimes, FaCopy } from "react-icons/fa";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const TripDashboard = () => {
  const [trips, setTrips] = useState([]);
  const [filteredTrips, setFilteredTrips] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    admin: "",
    currency: "₹",
    invoiceAmount: "",
    invoiceNumber: "",
    advanceReceive: "",
    advanceReceiveDate: "",
    balanceDate: "",
    vendorAmount: "",
    dates: [{ date: "", destination: "", resortName: "", shortItinerary: "", comment: "" }],
    inclusions: "",
    exclusions: "",
    hotels: [],
    cabs: [],
    vendors: [],
    others: [],
  });
  const [expandedTrip, setExpandedTrip] = useState(null);
  const [editingTrip, setEditingTrip] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [deleteConfirmTripId, setDeleteConfirmTripId] = useState(null);
  const [filters, setFilters] = useState({
    tripId: "",
    name: "",
    phone: "",
    date: "",
  });

  const generateUniqueTripId = async () => {
    let newTripId;
    let isUnique = false;
    const maxAttempts = 10;
    let attempts = 0;

    do {
      newTripId = Math.floor(100000 + Math.random() * 900000).toString();
      const docRef = await firebase.firestore().collection("wildlifecustomerData").doc(newTripId).get();
      isUnique = !docRef.exists;
      attempts++;
      if (attempts >= maxAttempts && !isUnique) {
        throw new Error("Unable to generate unique trip ID after maximum attempts");
      }
    } while (!isUnique);
    return newTripId;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await firebase.firestore()
          .collection("wildlifecustomerData")
          .orderBy("dates[0].date", "asc")
          .get();
        const tripData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            tripId: doc.id,
            ...data,
            hotels: data.hotels || [],
            cabs: data.cabs || [],
            vendors: data.vendors || [],
            others: data.others || [],
            inclusions: Array.isArray(data.inclusions) ? data.inclusions.join("\n") : (data.inclusions || ""),
            exclusions: Array.isArray(data.exclusions) ? data.exclusions.join("\n") : (data.exclusions || ""),
            currency: data.currency || "₹",
            vendorAmount: data.vendorAmount || "",
          };
        });
        tripData.sort((a, b) => new Date(a.dates[0].date) - new Date(b.dates[0].date));
        setTrips(tripData);
        setFilteredTrips(tripData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    const unsubscribe = firebase.firestore()
      .collection("wildlifecustomerData")
      .onSnapshot((snapshot) => {
        const updatedTrips = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            tripId: doc.id,
            ...data,
            hotels: data.hotels || [],
            cabs: data.cabs || [],
            vendors: data.vendors || [],
            others: data.others || [],
            inclusions: Array.isArray(data.inclusions) ? data.inclusions.join("\n") : (data.inclusions || ""),
            exclusions: Array.isArray(data.exclusions) ? data.exclusions.join("\n") : (data.exclusions || ""),
            currency: data.currency || "₹",
            vendorAmount: data.vendorAmount || "",
          };
        });
        updatedTrips.sort((a, b) => new Date(a.dates[0].date) - new Date(b.dates[0].date));
        setTrips(updatedTrips);
        setFilteredTrips(updatedTrips);
      });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...trips];
      if (filters.tripId) filtered = filtered.filter((trip) => trip.tripId.includes(filters.tripId));
      if (filters.name) filtered = filtered.filter((trip) => trip.name.toLowerCase().includes(filters.name.toLowerCase()));
      if (filters.phone) filtered = filtered.filter((trip) => trip.phone.includes(filters.phone));
      if (filters.date) filtered = filtered.filter((trip) => trip.dates[0].date === filters.date);
      setFilteredTrips(filtered);
    };
    applyFilters();
  }, [filters, trips]);

  const calculateBalance = (invoiceAmount, advance) => {
    const invoice = parseFloat(invoiceAmount) || 0;
    const adv = parseFloat(advance) || 0;
    return (invoice - adv).toFixed(2);
  };

  const calculateTotalPaid = (trip) => {
    const sections = ["hotels", "cabs", "vendors", "others"];
    return sections.reduce((total, section) => {
      const sectionTotal = trip[section].reduce((sum, item) => {
        const advance = parseFloat(item.advancePaidAmount) || 0;
        const balance = parseFloat(item.balanceAmount) || 0;
        const full = parseFloat(item.fullAmount) || 0;
        return sum + (full > 0 ? full : advance + balance);
      }, 0);
      return total + sectionTotal;
    }, 0).toFixed(2);
  };

  const calculateVendorBalance = (trip) => {
    const vendorAmount = parseFloat(trip.vendorAmount) || 0;
    const totalPaid = parseFloat(calculateTotalPaid(trip)) || 0;
    return (vendorAmount - totalPaid).toFixed(2);
  };

  const calculateSectionBalance = (item, trip) => {
    const vendorAmount = parseFloat(trip.vendorAmount) || 0;
    const totalPaidAcrossAll = parseFloat(calculateTotalPaid(trip)) || 0;
    const remainingVendorBalance = vendorAmount - totalPaidAcrossAll;
    return remainingVendorBalance >= 0 ? remainingVendorBalance.toFixed(2) : "0.00";
  };

  const isSectionFullyPaid = (trip) => {
    const vendorAmount = parseFloat(trip.vendorAmount) || 0;
    const totalPaid = parseFloat(calculateTotalPaid(trip)) || 0;
    return vendorAmount > 0 && totalPaid >= vendorAmount;
  };

  const calculateSpendings = (trip) => {
    return calculateTotalPaid(trip);
  };

  const handleInputChange = (e, index = null, type = null, field = null, dataSetter = setFormData, data = formData) => {
    const { name, value } = e.target;
    if (type && index !== null && field !== null) {
      const updatedItems = [...data[type]];
      updatedItems[index][field] = value;
      dataSetter({ ...data, [type]: updatedItems });
    } else if (index !== null) {
      const updatedDates = [...data.dates];
      updatedDates[index][name] = value;
      dataSetter({ ...data, dates: updatedDates });
    } else {
      dataSetter({ ...data, [name]: value });
    }
  };

  const addDate = () => {
    setFormData({
      ...formData,
      dates: [...formData.dates, { date: "", destination: "", resortName: "", shortItinerary: "", comment: "" }],
    });
  };

  const handleDeleteSectionItem = (section, index) => {
    const updatedData = [...editingSection.data];
    updatedData.splice(index, 1);
    setEditingSection({ ...editingSection, data: updatedData });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newTripId = await generateUniqueTripId();
      const tripData = {
        ...formData,
        tripId: newTripId,
        balanceToReceive: calculateBalance(formData.invoiceAmount, formData.advanceReceive),
        inclusions: formData.inclusions,
        exclusions: formData.exclusions,
      };
      await firebase.firestore().collection("wildlifecustomerData").doc(newTripId).set(tripData);
      setFormVisible(false);
      setFormData({
        name: "",
        phone: "",
        email: "",
        admin: "",
        currency: "₹",
        invoiceAmount: "",
        invoiceNumber: "",
        advanceReceive: "",
        advanceReceiveDate: "",
        balanceDate: "",
        vendorAmount: "",
        dates: [{ date: "", destination: "", resortName: "", shortItinerary: "", comment: "" }],
        inclusions: "",
        exclusions: "",
        hotels: [],
        cabs: [],
        vendors: [],
        others: [],
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Failed to create trip.");
    }
  };

  const handleEditSave = async () => {
    if (!editingTrip || !editingTrip.tripId) return;
    try {
      const updatedTrip = {
        ...editingTrip,
        balanceToReceive: calculateBalance(editingTrip.invoiceAmount, editingTrip.advanceReceive),
        inclusions: editingTrip.inclusions,
        exclusions: editingTrip.exclusions,
      };
      await firebase.firestore().collection("wildlifecustomerData").doc(editingTrip.tripId).set(updatedTrip);
      setEditingTrip(null);
    } catch (error) {
      console.error("Error updating document:", error);
      alert("Failed to update trip.");
    }
  };

  const handleCopyTrip = async (trip) => {
    try {
      const newTripId = await generateUniqueTripId();
      const copiedTrip = { ...trip, tripId: newTripId };
      setEditingTrip(copiedTrip);
    } catch (error) {
      console.error("Error generating new trip ID for copy:", error);
      alert("Failed to copy trip.");
    }
  };

  const handleDeleteConfirm = async (tripId) => {
    try {
      await firebase.firestore().collection("wildlifecustomerData").doc(tripId).delete();
      setDeleteConfirmTripId(null);
    } catch (error) {
      console.error("Error deleting document:", error);
      alert("Failed to delete trip.");
    }
  };

  const toggleTrip = (tripId) => setExpandedTrip(expandedTrip === tripId ? null : tripId);

  const handleSectionEdit = (tripId, section) => {
    const trip = trips.find((t) => t.tripId === tripId);
    setEditingSection({ tripId, section, data: trip[section] || [] });
  };

  const handleSectionSave = async () => {
    if (!editingSection) return;
    const updatedTrip = { ...trips.find((t) => t.tripId === editingSection.tripId), [editingSection.section]: editingSection.data };
    updatedTrip.balanceToReceive = calculateBalance(updatedTrip.invoiceAmount, updatedTrip.advanceReceive);
    try {
      await firebase.firestore().collection("wildlifecustomerData").doc(editingSection.tripId).set(updatedTrip);
      setEditingSection(null);
    } catch (error) {
      console.error("Error updating section:", error);
      alert("Failed to update section.");
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilter = (filterName) => setFilters((prev) => ({ ...prev, [filterName]: "" }));

  return (
    <div className="container-fluid p-0 m-0" style={{ fontFamily: "'Poppins', sans-serif", backgroundColor: "#f7f9fc", minHeight: "100vh" }}>
      <div className="bg-white p-3 shadow-sm" style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "#e67e22" }}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-3 align-items-center">
            <div className="position-relative">
              <input type="text" name="tripId" placeholder="Trip ID" value={filters.tripId} onChange={handleFilterChange} className="form-control rounded-3 shadow-sm pe-4" style={{ width: "150px", borderColor: "#e2e8f0", fontSize: "14px" }} />
              {filters.tripId && <FaTimes className="position-absolute" style={{ top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer", fontSize: "14px" }} onClick={() => resetFilter("tripId")} />}
            </div>
            <div className="position-relative">
              <input type="text" name="name" placeholder="Name" value={filters.name} onChange={handleFilterChange} className="form-control rounded-3 shadow-sm pe-4" style={{ width: "150px", borderColor: "#e2e8f0", fontSize: "14px" }} />
              {filters.name && <FaTimes className="position-absolute" style={{ top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer", fontSize: "14px" }} onClick={() => resetFilter("name")} />}
            </div>
            <div className="position-relative">
              <input type="text" name="phone" placeholder="Contact" value={filters.phone} onChange={handleFilterChange} className="form-control rounded-3 shadow-sm pe-4" style={{ width: "150px", borderColor: "#e2e8f0", fontSize: "14px" }} />
              {filters.phone && <FaTimes className="position-absolute" style={{ top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer", fontSize: "14px" }} onClick={() => resetFilter("phone")} />}
            </div>
            <div className="position-relative">
              <input type="date" name="date" value={filters.date} onChange={handleFilterChange} className="form-control rounded-3 shadow-sm pe-4" style={{ width: "150px", borderColor: "#e2e8f0", fontSize: "14px", marginLeft: "10px" }} />
              {filters.date && <FaTimes className="position-absolute" style={{ top: "50%", right: "10px", transform: "translateY(-50%)", cursor: "pointer", fontSize: "14px" }} onClick={() => resetFilter("date")} />}
            </div>
          </div>
          <button className="btn btn-primary rounded-3 px-4 py-2 shadow-sm" onClick={() => setFormVisible(true)} style={{ fontSize: "14px", fontWeight: 500 }}>
            <FaPlus className="me-2" /> Add Trip
          </button>
        </div>
      </div>

      <div className="p-3">
        {filteredTrips.length > 0 ? (
          filteredTrips.map((trip) => (
            <div key={trip.tripId} className="card shadow-sm rounded-3 border-0 mb-3" style={{ backgroundColor: "#fff", transition: "all 0.3s" }}>
              <div className="card-header d-flex justify-content-between align-items-center rounded-top-3" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem 1rem", cursor: "pointer" }} onClick={() => toggleTrip(trip.tripId)}>
                <h6 className="mb-0 fw-bold" style={{ fontSize: "18px" }}>
                  {expandedTrip === trip.tripId ? <FaMinus className="me-2" /> : <FaPlus className="me-2" />}
                  Trip #W{trip.tripId} - {trip.name} ({trip.dates[0].date}) - Admin: {trip.admin || "N/A"}
                </h6>
                <div>
                  <FaCopy className="me-2 cursor-pointer" style={{ fontSize: "18px" }} onClick={(e) => { e.stopPropagation(); handleCopyTrip(trip); }} />
                  <FaEdit className="me-2 cursor-pointer" style={{ fontSize: "18px" }} onClick={(e) => { e.stopPropagation(); setEditingTrip({ ...trip }); }} />
                  <FaTrash className="cursor-pointer" style={{ fontSize: "18px" }} onClick={(e) => { e.stopPropagation(); setDeleteConfirmTripId(trip.tripId); }} />
                </div>
              </div>
              {expandedTrip === trip.tripId && (
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card shadow-sm rounded-3 border-0 mb-3">
                        <div className="card-header" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem" }}>
                          <h6 className="fw-bold mb-0" style={{ fontSize: "16px" }}>Customer Details</h6>
                        </div>
                        <div className="card-body" style={{ padding: "1rem" }}>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Trip ID:</strong> W{trip.tripId || ""}</div>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Name:</strong> {trip.name || "N/A"}</div>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Contact:</strong> {trip.phone || "N/A"}</div>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Email:</strong> {trip.email || "N/A"}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card shadow-sm rounded-3 border-0 mb-3">
                        <div className="card-header" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem" }}>
                          <h6 className="fw-bold mb-0" style={{ fontSize: "16px" }}>Invoice Details</h6>
                        </div>
                        <div className="card-body" style={{ padding: "1rem" }}>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Invoice Amount:</strong> <strong>{trip.currency}{trip.invoiceAmount || "N/A"}</strong> (#{trip.invoiceNumber || "N/A"})</div>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Advance:</strong> {trip.currency}{trip.advanceReceive || "N/A"} {trip.advanceReceiveDate ? `(${trip.advanceReceiveDate})` : ""}</div>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Balance:</strong> {trip.currency}{calculateBalance(trip.invoiceAmount, trip.advanceReceive)} {trip.balanceDate ? `(${trip.balanceDate})` : ""}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card shadow-sm rounded-3 border-0 mb-3">
                        <div className="card-header" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem" }}>
                          <h6 className="fw-bold mb-0" style={{ fontSize: "16px" }}>Financial Summary</h6>
                        </div>
                        <div className="card-body" style={{ padding: "1rem" }}>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Invoice Amount:</strong> <strong>{trip.currency}{trip.invoiceAmount || 0}</strong></div>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Total Spendings:</strong> {trip.currency}{calculateSpendings(trip)}</div>
                          <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Profit:</strong> <strong>{trip.currency}{(parseFloat(trip.invoiceAmount || 0) - calculateSpendings(trip)).toFixed(2)}</strong></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ borderColor: "#e2e8f0" }} />
                  <div className="mt-3">
                    <h6 className="fw-bold mb-3" style={{ color: "#1a202c", fontSize: "16px" }}>Itinerary</h6>
                    <div className="row">
                      {trip.dates && trip.dates.length > 0 ? (
                        trip.dates.map((date, idx) => (
                          <div key={idx} className="col-md-6 mb-3">
                            <div className="card shadow-sm rounded-3 border-0">
                              <div className="card-header" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem" }}>
                                <div style={{ fontSize: "14px" }}><strong>Day {idx + 1}</strong></div>
                              </div>
                              <div className="card-body" style={{ padding: "1rem" }}>
                                <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Date:</strong> {date.date || "N/A"}</div>
                                <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Destination:</strong> {date.destination || "N/A"}</div>
                                <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Resort Name:</strong> {date.resortName || "N/A"}</div>
                                <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Short Itinerary:</strong> {date.shortItinerary || "N/A"}</div>
                                <div style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}><strong style={{ marginRight: "8px" }}>Comment:</strong> {date.comment || "N/A"}</div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div style={{ color: "#4a5568", fontSize: "14px" }}>No itinerary available</div>
                      )}
                    </div>
                  </div>
                  <hr style={{ borderColor: "#e2e8f0" }} />
                  <div className="mt-3">
                    <h6 className="fw-bold mb-3" style={{ color: "#1a202c", fontSize: "16px" }}>Inclusions & Exclusions</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="card shadow-sm rounded-3 border-0 mb-3">
                          <div className="card-header" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem" }}>
                            <h6 className="fw-bold mb-0" style={{ fontSize: "16px" }}>Inclusions</h6>
                          </div>
                          <div className="card-body" style={{ padding: "1rem" }}>
                            {typeof trip.inclusions === "string" && trip.inclusions.trim() ? (
                              trip.inclusions.split("\n").map((inc, idx) => (
                                <div key={idx} style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}>- {inc || "N/A"}</div>
                              ))
                            ) : (
                              <div style={{ color: "#4a5568", fontSize: "14px" }}>No inclusions added</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card shadow-sm rounded-3 border-0 mb-3">
                          <div className="card-header" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem" }}>
                            <h6 className="fw-bold mb-0" style={{ fontSize: "16px" }}>Exclusions</h6>
                          </div>
                          <div className="card-body" style={{ padding: "1rem" }}>
                            {typeof trip.exclusions === "string" && trip.exclusions.trim() ? (
                              trip.exclusions.split("\n").map((exc, idx) => (
                                <div key={idx} style={{ color: "#4a5568", fontSize: "14px", marginBottom: "8px" }}>- {exc || "N/A"}</div>
                              ))
                            ) : (
                              <div style={{ color: "#4a5568", fontSize: "14px" }}>No exclusions added</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style={{ borderColor: "#e2e8f0" }} />
                  <div className="mt-3">
                    <h6 className="fw-bold mb-3" style={{ color: "#1a202c", fontSize: "16px" }}>Expenses</h6>
                    <div className="row">
                      {["hotels", "cabs", "vendors", "others"].map((section) => (
                        <div className="col-md-3" key={section}>
                          <div className="card shadow-sm rounded-3 border-0 mb-3">
                            <div className="card-header" style={{ background: "linear-gradient(135deg, #ff6b6b, #e67e22)", color: "#fff", padding: "0.75rem" }}>
                              <h6 className="fw-bold mb-0" style={{ fontSize: "16px" }}>{section.charAt(0).toUpperCase() + section.slice(1)}</h6>
                            </div>
                            <div className="card-body" style={{ padding: "1rem" }}>
                              {editingSection && editingSection.tripId === trip.tripId && editingSection.section === section ? (
                                <>
                                  {editingSection.data.map((item, idx) => (
                                    <div key={idx} className="mb-2 position-relative">
                                      <input 
                                        type="text" 
                                        value={item.name || ""} 
                                        onChange={(e) => { 
                                          const updatedData = [...editingSection.data]; 
                                          updatedData[idx].name = e.target.value; 
                                          setEditingSection({ ...editingSection, data: updatedData }); 
                                        }} 
                                        className="form-control mb-1" 
                                        placeholder={`${section.slice(0, -1)} Name`} 
                                        style={{ borderColor: "#e2e8f0", fontSize: "14px" }} 
                                      />
                                      <select
                                        value={item.paymentType || "Advance"}
                                        onChange={(e) => {
                                          const updatedData = [...editingSection.data];
                                          updatedData[idx].paymentType = e.target.value;
                                          setEditingSection({ ...editingSection, data: updatedData });
                                        }}
                                        className="form-control mb-1"
                                        style={{ borderColor: "#e2e8f0", fontSize: "14px" }}
                                      >
                                        <option value="Advance">Advance</option>
                                        <option value="Balance">Balance</option>
                                        <option value="Full Amount">Full Amount</option>
                                      </select>
                                      {item.paymentType === "Advance" && (
                                        <>
                                          <input 
                                            type="number" 
                                            value={item.advancePaidAmount || ""} 
                                            onChange={(e) => { 
                                              const updatedData = [...editingSection.data]; 
                                              updatedData[idx].advancePaidAmount = e.target.value; 
                                              setEditingSection({ ...editingSection, data: updatedData }); 
                                            }} 
                                            className="form-control mb-1" 
                                            placeholder="Advance Paid Amount" 
                                            style={{ borderColor: "#e2e8f0", fontSize: "14px" }} 
                                          />
                                          <label style={{ color: "#4a5568", fontSize: "12px" }}>Advance Paid Date</label>
                                          <input 
                                            type="date" 
                                            value={item.advancePaidDate || ""} 
                                            onChange={(e) => { 
                                              const updatedData = [...editingSection.data]; 
                                              updatedData[idx].advancePaidDate = e.target.value; 
                                              setEditingSection({ ...editingSection, data: updatedData }); 
                                            }} 
                                            className="form-control mb-1" 
                                            style={{ borderColor: "#e2e8f0", fontSize: "14px" }} 
                                          />
                                        </>
                                      )}
                                      {item.paymentType === "Balance" && (
                                        <>
                                          <input 
                                            type="number" 
                                            value={item.balanceAmount || ""} 
                                            onChange={(e) => { 
                                              const updatedData = [...editingSection.data]; 
                                              updatedData[idx].balanceAmount = e.target.value; 
                                              setEditingSection({ ...editingSection, data: updatedData }); 
                                            }} 
                                            className="form-control mb-1" 
                                            placeholder="Balance Amount" 
                                            style={{ borderColor: "#e2e8f0", fontSize: "14px" }} 
                                          />
                                          <label style={{ color: "#4a5568", fontSize: "12px" }}>Balance Paid Date</label>
                                          <input 
                                            type="date" 
                                            value={item.balancePaidDate || ""} 
                                            onChange={(e) => { 
                                              const updatedData = [...editingSection.data]; 
                                              updatedData[idx].balancePaidDate = e.target.value; 
                                              setEditingSection({ ...editingSection, data: updatedData }); 
                                            }} 
                                            className="form-control mb-1" 
                                            style={{ borderColor: "#e2e8f0", fontSize: "14px" }} 
                                          />
                                        </>
                                      )}
                                      {item.paymentType === "Full Amount" && (
                                        <>
                                          <input 
                                            type="number" 
                                            value={item.fullAmount || ""} 
                                            onChange={(e) => { 
                                              const updatedData = [...editingSection.data]; 
                                              updatedData[idx].fullAmount = e.target.value; 
                                              updatedData[idx].advancePaidAmount = "";
                                              updatedData[idx].balanceAmount = "";
                                              setEditingSection({ ...editingSection, data: updatedData }); 
                                            }} 
                                            className="form-control mb-1" 
                                            placeholder="Full Amount" 
                                            style={{ borderColor: "#e2e8f0", fontSize: "14px" }} 
                                          />
                                          <label style={{ color: "#4a5568", fontSize: "12px" }}>Full Amount Paid Date</label>
                                          <input 
                                            type="date" 
                                            value={item.fullAmountDate || ""} 
                                            onChange={(e) => { 
                                              const updatedData = [...editingSection.data]; 
                                              updatedData[idx].fullAmountDate = e.target.value; 
                                              setEditingSection({ ...editingSection, data: updatedData }); 
                                            }} 
                                            className="form-control mb-1" 
                                            style={{ borderColor: "#e2e8f0", fontSize: "14px" }} 
                                          />
                                        </>
                                      )}
                                      <div style={{ color: "#4a5568", fontSize: "14px" }}>
                                        <strong>Total Vendor Balance: </strong>{trip.currency}{calculateVendorBalance(trip)}
                                      </div>
                                      <FaTrash 
                                        className="position-absolute" 
                                        style={{ top: "10px", right: "10px", cursor: "pointer", color: "#e53e3e", fontSize: "14px" }} 
                                        onClick={() => handleDeleteSectionItem(section, idx)} 
                                      />
                                    </div>
                                  ))}
                                  <button 
                                    className="btn btn-outline-success btn-sm mt-2" 
                                    onClick={() => setEditingSection({ 
                                      ...editingSection, 
                                      data: [...editingSection.data, { name: "", paymentType: "Advance", advancePaidAmount: "", advancePaidDate: "", balanceAmount: "", balancePaidDate: "", fullAmount: "", fullAmountDate: "" }] 
                                    })} 
                                    style={{ fontSize: "14px" }}
                                  >
                                    <FaPlus /> Add {section.slice(0, -1)}
                                  </button>
                                  <div className="mt-2">
                                    <button className="btn btn-success btn-sm me-2" onClick={handleSectionSave} style={{ fontSize: "14px" }}><FaSave /> Save</button>
                                    <button className="btn btn-danger btn-sm" onClick={() => setEditingSection(null)} style={{ fontSize: "14px" }}><FaBan /> Cancel</button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  {trip[section].length > 0 ? (
                                    trip[section].map((item, idx) => (
                                      <div key={idx} className="mb-2" style={{ color: "#4a5568", fontSize: "14px" }}>
                                        <div><strong>Name:</strong> {item.name || "N/A"}</div>
                                        <div><strong>Total Vendor Amount:</strong> {trip.currency}{trip.vendorAmount || "N/A"}</div>
                                        {isSectionFullyPaid(trip) || item.paymentType === "Full Amount" ? (
                                          <div><strong>Full Amount Paid:</strong> {trip.currency}{item.fullAmount || (parseFloat(item.advancePaidAmount || 0) + parseFloat(item.balanceAmount || 0))} {item.fullAmountDate ? `(${item.fullAmountDate})` : ""}</div>
                                        ) : (
                                          <>
                                            {item.advancePaidAmount && (
                                              <div><strong>Advance Paid:</strong> {trip.currency}{item.advancePaidAmount || 0} {item.advancePaidDate ? `(${item.advancePaidDate})` : ""}</div>
                                            )}
                                            {item.balanceAmount && (
                                              <div><strong>Balance Paid:</strong> {trip.currency}{item.balanceAmount || 0} {item.balancePaidDate ? `(${item.balancePaidDate})` : ""}</div>
                                            )}
                                            <div><strong>Remaining Vendor Balance:</strong> {trip.currency}{calculateSectionBalance(item, trip)}</div>
                                          </>
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    <div style={{ color: "#4a5568", fontSize: "14px" }}>No {section} added</div>
                                  )}
                                  <button 
                                    className="btn btn-outline-success btn-sm mt-2" 
                                    onClick={() => handleSectionEdit(trip.tripId, section)} 
                                    style={{ fontSize: "14px" }}
                                  >
                                    <FaPlus /> Add {section.slice(0, -1)}
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: "calc(100vh - 100px)" }}>
            <h3 className="text-muted mb-4" style={{ fontSize: "24px", color: "#4a5568" }}>No Trips Added Yet</h3>
            <button 
              className="btn btn-primary rounded-3 px-5 py-2 shadow-sm" 
              onClick={() => setFormVisible(true)} 
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              <FaPlus className="me-2" /> Add Your First Trip
            </button>
          </div>
        )}
      </div>

      {formVisible && (
        <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "500px" }}>
            <div className="modal-content rounded-3 shadow-lg">
              <div className="modal-header bg-light border-0" style={{ padding: "1rem" }}>
                <h5 className="modal-title fw-bold" style={{ color: "#e67e22", fontSize: "18px" }}>Add New Trip</h5>
                <button type="button" className="btn-close" onClick={() => setFormVisible(false)}></button>
              </div>
              <div className="modal-body p-4">
                <div>
                  <div className="row g-3">
                    <div className="col-12"><input type="text" name="name" placeholder="Name" value={formData.name} onChange={(e) => handleInputChange(e)} required className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12"><input type="text" name="phone" placeholder="Contact" value={formData.phone} onChange={(e) => handleInputChange(e)} required className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12"><input type="email" name="email" placeholder="Email" value={formData.email} onChange={(e) => handleInputChange(e)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12">
                      <select name="admin" value={formData.admin} onChange={(e) => handleInputChange(e)} required className="form-select rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }}>
                        <option value="" disabled>Select Admin</option>
                        <option value="ck@travelunbounded.com">Chandrashekar</option>
                        <option value="sanjeev.katoch@travelunbounded.com">Sanjeev</option>
                        <option value="mamta@travelunbounded.com">Mamta</option>
                        <option value="ds@travelunbounded.com">Sai</option>
                      </select>
                    </div>
                    <div className="col-12 d-flex align-items-center">
                      <select name="currency" value={formData.currency} onChange={(e) => handleInputChange(e)} className="form-select rounded-3 shadow-sm me-2" style={{ width: "80px", borderColor: "#e2e8f0", fontSize: "14px" }}>
                        <option value="₹">₹ (Rupee)</option>
                        <option value="$">$ (Dollar)</option>
                      </select>
                      <input type="number" name="invoiceAmount" placeholder="Invoice Amount" value={formData.invoiceAmount} onChange={(e) => handleInputChange(e)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                    <div className="col-12"><input type="text" name="invoiceNumber" placeholder="Invoice Number" value={formData.invoiceNumber} onChange={(e) => handleInputChange(e)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12"><input type="number" name="advanceReceive" placeholder="Advance Receive" value={formData.advanceReceive} onChange={(e) => handleInputChange(e)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12">
                      <label style={{ color: "#4a5568", fontSize: "12px" }}>Advance Receive Date</label>
                      <input type="date" name="advanceReceiveDate" value={formData.advanceReceiveDate} onChange={(e) => handleInputChange(e)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                    <div className="col-12">
                      <label style={{ color: "#4a5568", fontSize: "12px" }}>Balance Due Date</label>
                      <input type="date" name="balanceDate" value={formData.balanceDate} onChange={(e) => handleInputChange(e)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                    <div className="col-12">
                      <input type="number" name="vendorAmount" placeholder="Vendor Amount" value={formData.vendorAmount} onChange={(e) => handleInputChange(e)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                  </div>
                  <hr style={{ borderColor: "#e2e8f0" }} />
                  <div className="mt-3">
                    <h6 className="fw-bold" style={{ color: "#1a202c", fontSize: "16px" }}>Itinerary</h6>
                    {formData.dates.map((item, index) => (
                      <div key={index} className="mt-3 bg-light p-3 rounded-3 shadow-sm">
                        <div className="row g-3">
                          <div className="col-12">
                            <label style={{ color: "#4a5568", fontSize: "12px" }}>Day {index + 1}</label>
                            <input type="date" name="date" value={item.date} onChange={(e) => handleInputChange(e, index)} required className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                          </div>
                          <div className="col-12"><input type="text" name="destination" placeholder="Destination" value={item.destination} onChange={(e) => handleInputChange(e, index)} required className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                          <div className="col-12"><input type="text" name="resortName" placeholder="Resort Name" value={item.resortName} onChange={(e) => handleInputChange(e, index)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                          <div className="col-12"><input type="text" name="shortItinerary" placeholder="Short Itinerary" value={item.shortItinerary} onChange={(e) => handleInputChange(e, index)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                          <div className="col-12"><input type="text" name="comment" placeholder="Comment" value={item.comment} onChange={(e) => handleInputChange(e, index)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="btn btn-outline-success rounded-3 mt-3" onClick={addDate} style={{ fontSize: "14px" }}>Add Day</button>
                  </div>
                  <hr style={{ borderColor: "#e2e8f0" }} />
                  <div className="mt-3">
                    <h6 className="fw-bold" style={{ color: "#1a202c", fontSize: "16px" }}>Inclusions & Exclusions</h6>
                    <div className="row g-3">
                      <div className="col-12">
                        <textarea
                          name="inclusions"
                          placeholder="Enter inclusions (one per line)"
                          value={formData.inclusions}
                          onChange={(e) => handleInputChange(e)}
                          className="form-control rounded-3 shadow-sm"
                          rows="4"
                          style={{ borderColor: "#e2e8f0", fontSize: "14px" }}
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="exclusions"
                          placeholder="Enter exclusions (one per line)"
                          value={formData.exclusions}
                          onChange={(e) => handleInputChange(e)}
                          className="form-control rounded-3 shadow-sm"
                          rows="4"
                          style={{ borderColor: "#e2e8f0", fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-0" style={{ padding: "1rem" }}>
                    <button onClick={handleSubmit} className="btn btn-primary rounded-3 px-4" style={{ fontSize: "14px" }}>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {editingTrip && (
        <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "500px" }}>
            <div className="modal-content rounded-3 shadow-lg">
              <div className="modal-header bg-light border-0" style={{ padding: "1rem" }}>
                <h5 className="modal-title fw-bold" style={{ color: "#e67e22", fontSize: "18px" }}>
                  {editingTrip.tripId !== trips.find(t => t.tripId === editingTrip.tripId)?.tripId ? "Create Trip from Copy" : "Edit Trip"}
                </h5>
                <button type="button" className="btn-close" onClick={() => setEditingTrip(null)}></button>
              </div>
              <div className="modal-body p-4">
                <div>
                  <div className="row g-3">
                    <div className="col-12"><input type="text" name="name" value={editingTrip.name || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Name" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12"><input type="text" name="phone" value={editingTrip.phone || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Contact" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12"><input type="email" name="email" value={editingTrip.email || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Email" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12">
                      <select name="admin" value={editingTrip.admin || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-select rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }}>
                        <option value="" disabled>Select Admin</option>
                        <option value="ck@travelunbounded.com">Chandrashekar</option>
                        <option value="sanjeev.katoch@travelunbounded.com">Sanjeev</option>
                        <option value="mamta@travelunbounded.com">Mamta</option>
                        <option value="ds@travelunbounded.com">Sai</option>
                      </select>
                    </div>
                    <div className="col-12 d-flex align-items-center">
                      <select name="currency" value={editingTrip.currency || "₹"} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-select rounded-3 shadow-sm me-2" style={{ width: "80px", borderColor: "#e2e8f0", fontSize: "14px" }}>
                        <option value="₹">₹ (Rupee)</option>
                        <option value="$">$ (Dollar)</option>
                      </select>
                      <input type="number" name="invoiceAmount" value={editingTrip.invoiceAmount || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Invoice Amount" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                    <div className="col-12"><input type="text" name="invoiceNumber" value={editingTrip.invoiceNumber || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Invoice Number" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12"><input type="number" name="advanceReceive" value={editingTrip.advanceReceive || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Advance Receive" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                    <div className="col-12">
                      <label style={{ color: "#4a5568", fontSize: "12px" }}>Advance Receive Date</label>
                      <input type="date" name="advanceReceiveDate" value={editingTrip.advanceReceiveDate || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                    <div className="col-12">
                      <label style={{ color: "#4a5568", fontSize: "12px" }}>Balance Due Date</label>
                      <input type="date" name="balanceDate" value={editingTrip.balanceDate || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                    <div className="col-12">
                      <input type="number" name="vendorAmount" value={editingTrip.vendorAmount || ""} onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Vendor Amount" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                    </div>
                  </div>
                  <hr style={{ borderColor: "#e2e8f0" }} />
                  <div className="mt-3">
                    <h6 className="fw-bold" style={{ color: "#1a202c", fontSize: "16px" }}>Itinerary</h6>
                    {editingTrip.dates.map((item, index) => (
                      <div key={index} className="mt-3 bg-light p-3 rounded-3 shadow-sm">
                        <div className="row g-3">
                          <div className="col-12">
                            <label style={{ color: "#4a5568", fontSize: "12px" }}>Day {index + 1}</label>
                            <input type="date" name="date" value={item.date || ""} onChange={(e) => handleInputChange(e, index, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} />
                          </div>
                          <div className="col-12"><input type="text" name="destination" value={item.destination || ""} onChange={(e) => handleInputChange(e, index, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Destination" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                          <div className="col-12"><input type="text" name="resortName" value={item.resortName || ""} onChange={(e) => handleInputChange(e, index, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Resort Name" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                          <div className="col-12"><input type="text" name="shortItinerary" value={item.shortItinerary || ""} onChange={(e) => handleInputChange(e, index, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Short Itinerary" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                          <div className="col-12"><input type="text" name="comment" value={item.comment || ""} onChange={(e) => handleInputChange(e, index, null, null, setEditingTrip, editingTrip)} className="form-control rounded-3 shadow-sm" placeholder="Comment" style={{ borderColor: "#e2e8f0", fontSize: "14px" }} /></div>
                        </div>
                      </div>
                    ))}
                    <button type="button" className="btn btn-outline-success rounded-3 mt-3" onClick={() => setEditingTrip({ ...editingTrip, dates: [...editingTrip.dates, { date: "", destination: "", resortName: "", shortItinerary: "", comment: "" }] })} style={{ fontSize: "14px" }}>Add Day</button>
                  </div>
                  <hr style={{ borderColor: "#e2e8f0" }} />
                  <div className="mt-3">
                    <h6 className="fw-bold" style={{ color: "#1a202c", fontSize: "16px" }}>Inclusions & Exclusions</h6>
                    <div className="row g-3">
                      <div className="col-12">
                        <textarea
                          name="inclusions"
                          placeholder="Enter inclusions (one per line)"
                          value={editingTrip.inclusions || ""}
                          onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)}
                          className="form-control rounded-3 shadow-sm"
                          rows="4"
                          style={{ borderColor: "#e2e8f0", fontSize: "14px" }}
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="exclusions"
                          placeholder="Enter exclusions (one per line)"
                          value={editingTrip.exclusions || ""}
                          onChange={(e) => handleInputChange(e, null, null, null, setEditingTrip, editingTrip)}
                          className="form-control rounded-3 shadow-sm"
                          rows="4"
                          style={{ borderColor: "#e2e8f0", fontSize: "14px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-0" style={{ padding: "1rem" }}>
                    <button onClick={handleEditSave} className="btn btn-success rounded-3 px-4 shadow-sm" style={{ fontSize: "14px" }}><FaSave className="me-2" /> Save</button>
                    <button type="button" className="btn btn-outline-danger rounded-3 px-4 shadow-sm" onClick={() => setEditingTrip(null)} style={{ fontSize: "14px" }}><FaBan className="me-2" /> Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteConfirmTripId && (
        <div className="modal fade show d-block" tabIndex="-1" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
          <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "400px" }}>
            <div className="modal-content rounded-3 shadow-lg">
              <div className="modal-header bg-light border-0" style={{ padding: "1rem" }}>
                <h5 className="modal-title fw-bold" style={{ color: "#e67e22", fontSize: "18px" }}>Confirm Delete</h5>
                <button type="button" className="btn-close" onClick={() => setDeleteConfirmTripId(null)}></button>
              </div>
              <div className="modal-body p-4">
                <p className="text-danger fw-bold mb-2" style={{ fontSize: "14px" }}>Are you sure you want to delete Trip #W{deleteConfirmTripId}?</p>
                <div className="d-flex gap-2 justify-content-end">
                  <button className="btn btn-danger btn-sm rounded-3 px-3" onClick={() => handleDeleteConfirm(deleteConfirmTripId)} style={{ fontSize: "14px" }}>Yes</button>
                  <button className="btn btn-outline-secondary btn-sm rounded-3 px-3" onClick={() => setDeleteConfirmTripId(null)} style={{ fontSize: "14px" }}>No</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TripDashboard;