import React, { useState, useEffect } from "react";
import "./index.css";
import { FiX } from "react-icons/fi";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-number-input";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import { HelmetProvider, Helmet } from "react-helmet-async";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const BookingForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [holidayTypes, setHolidayTypes] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [loadingHoliday, setLoadingHoliday] = useState(false);
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    holidayType: "",
    holidayTypeId: "",
    destination: "",
    destinationId: "",
    noOfAdults: "",
    kidsAbove5: "",
    travelDate: "",
    stayPreference: "",
    stayDuration: "",
  });

  useEffect(() => {
    const db = firebase.firestore();
    setLoadingHoliday(true);
    db.collection("DropdownDestinations")
      .get()
      .then((querySnapshot) => {
        const holidayData = querySnapshot.docs.map((doc) => ({
          Label: doc.data().Label || doc.id,
          TAOCSVInstId: doc.id,
        }));
        setHolidayTypes(holidayData);
      })
      .catch((error) => {
        console.error("Error fetching holiday types:", error);
        setHolidayTypes([]);
      })
      .finally(() => setLoadingHoliday(false));
  }, []);

  useEffect(() => {
    if (!formData.holidayTypeId) {
      setDestinations([]);
      return;
    }

    const db = firebase.firestore();
    setLoadingDestination(true);
    db.collection("DropdownDestinations")
      .doc(formData.holidayTypeId)
      .collection(formData.holidayTypeId)
      .get()
      .then((querySnapshot) => {
        const destData = querySnapshot.docs.map((doc) => ({
          Label: doc.data().Label || doc.id,
          TAOCSVInstId: doc.id,
        }));
        setDestinations(destData);
      })
      .catch((error) => {
        console.error("Error fetching destinations:", error);
        setDestinations([]);
      })
      .finally(() => setLoadingDestination(false));
  }, [formData.holidayTypeId]);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    try {
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );
      const confirmation = await firebase
        .auth()
        .signInWithPhoneNumber(`+${phoneNumber}`, appVerifier);
      setConfirmationResult(confirmation);
      toast.success("OTP sent successfully");
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      await confirmationResult.confirm(otp);
      toast.success("OTP Verified Successfully");
      setOtp("");
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Please Enter Correct OTP");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "holidayType") {
      const selectedHoliday = holidayTypes.find((ht) => ht.Label === value);
      setFormData((prevData) => ({
        ...prevData,
        holidayType: value,
        holidayTypeId: selectedHoliday ? selectedHoliday.TAOCSVInstId : null,
        destination: "",
        destinationId: null,
      }));
    } else if (name === "destination") {
      const selectedDestination = destinations.find((d) => d.Label === value);
      setFormData((prevData) => ({
        ...prevData,
        destination: value,
        destinationId: selectedDestination ? selectedDestination.TAOCSVInstId : null,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        name,
        email,
        holidayType,
        destination,
        noOfAdults,
        kidsAbove5,
        travelDate,
        stayPreference,
        stayDuration,
      } = formData;

      const randomSuffix = Math.random()
        .toString(36)
        .substring(2, 6)
        .toUpperCase();
      const bookingId = `${destination.toUpperCase()}${randomSuffix}`;

      const db = firebase.firestore();
      await db.collection("formData").add({
        name,
        email,
        holidayType,
        destination,
        phoneNumber: `${phoneNumber}`,
        noOfAdults,
        kidsAbove5,
        travelDate,
        stayPreference,
        stayDuration,
        bookingId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      });

      const response1 = await fetch(
        "https://formbackend.netlify.app/submit-new",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phoneNumber: `${phoneNumber}`,
            holidayType,
            destination,
            noOfAdults,
            kidsAbove5,
            travelDate,
            stayPreference,
            stayDuration,
            bookingId,
          }),
        }
      );

      const data1 = await response1.json();
      console.log("API response:", data1.message);

      toast.success("Booking Successful");
      setFormData({
        name: "",
        email: "",
        holidayType: "",
        holidayTypeId: "",
        destination: "",
        destinationId: "",
        noOfAdults: "",
        kidsAbove5: "",
        travelDate: "",
        stayPreference: "",
        stayDuration: "",
      });
      setPhoneNumber("");

      if (window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-870473623/KRgLCN7oh9QZEJe_iZ8D",
        });
      } else {
        console.error("Google Tag not initialized");
      }

      window.location.reload();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting booking. Please try again.");
    }
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Book your journey now with Travel Unbounded.</title>
          <meta
            name="description"
            content="Book now for an unforgettable journey into the heart of the wild. Don't miss out on thrilling safaris and breathtaking encounters"
          />
        </Helmet>
        <Toaster toastOptions={{ duration: 5000 }} />
        <div className="booking">
          <div className="section" id="booking">
            <div className="section-center">
              <div className="container">
                <div className="row">
                  <div className="booking-form-new">
                    <div
                      onClick={handleGoBack}
                      className="close-button-new-form"
                    >
                      <FiX size={20} />
                    </div>
                    <h1 className="booking-form-new-head mb-3 mt-3">
                      Book Your Dream Holiday Today!
                    </h1>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Name*</span>
                            <input
                              className="form-control"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              type="text"
                              placeholder="Enter your name"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Email*</span>
                            <input
                              className="form-control"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              type="email"
                              placeholder="Enter your email"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Holiday Type*</span>
                            <select
                              className="form-control"
                              name="holidayType"
                              value={formData.holidayType || ""}
                              onChange={handleChange}
                              required
                            >
                              <option value="" disabled>
                                {loadingHoliday ? "" : "Choose Holiday Type"}
                              </option>
                              {holidayTypes.length > 0 ? (
                                holidayTypes.map((holiday) => (
                                  <option
                                    key={holiday.TAOCSVInstId}
                                    value={holiday.Label}
                                  >
                                    {holiday.Label}
                                  </option>
                                ))
                              ) : (
                                !loadingHoliday && (
                                  <option disabled>
                                    No holiday types available
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Destination*</span>
                            <select
                              className="form-control"
                              name="destination"
                              value={formData.destination || ""}
                              onChange={handleChange}
                              required
                              disabled={!formData.holidayTypeId}
                            >
                              <option value="" disabled>
                                {loadingDestination ? "" : "Choose Destination"}
                              </option>
                              {destinations.length > 0 ? (
                                destinations.map((destination) => (
                                  <option
                                    key={destination.TAOCSVInstId}
                                    value={destination.Label}
                                  >
                                    {destination.Label}
                                  </option>
                                ))
                              ) : (
                                !loadingDestination && (
                                  <option disabled>
                                    No destinations available
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">No. of Adults*</span>
                            <input
                              className="form-control"
                              name="noOfAdults"
                              value={formData.noOfAdults}
                              onChange={handleChange}
                              type="number"
                              placeholder="Enter number of adults"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Kids Above 5yrs*</span>
                            <input
                              className="form-control"
                              name="kidsAbove5"
                              value={formData.kidsAbove5}
                              onChange={handleChange}
                              type="number"
                              placeholder="Kids above 5 years"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Travel Date*</span>
                            <input
                              className="form-control"
                              name="travelDate"
                              value={formData.travelDate}
                              onChange={handleChange}
                              type="date"
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Stay Preference*</span>
                            <select
                              className="form-control"
                              name="stayPreference"
                              value={formData.stayPreference}
                              onChange={handleChange}
                              required
                            >
                              <option value="" disabled>
                                Choose Stay Preference
                              </option>
                              <option value="3 star">3 Star</option>
                              <option value="4 star">4 Star</option>
                              <option value="5 star">5 Star</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span className="form-label">Stay Duration*</span>
                            <select
                              className="form-control"
                              name="stayDuration"
                              value={formData.stayDuration}
                              onChange={handleChange}
                              required
                            >
                              <option value="" disabled>
                                Choose Stay Duration
                              </option>
                              <option value="2 Nights">2 Nights</option>
                              <option value="3 Nights">3 Nights</option>
                              <option value="4 Nights">4 Nights</option>
                              <option value="5+ Nights">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12 form-group-inline">
                          <div className="form-group">
                            <span className="form-label">
                              Enter Phone Number*
                            </span>
                            <PhoneInput
                              defaultCountry="IN"
                              value={phoneNumber}
                              className="phone-placeholder"
                              placeholder="Enter Contact Number"
                              onChange={(value) => setPhoneNumber(value)}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <span className="form-label"></span>
                            <button
                              type="button"
                              onClick={handleSendOTP}
                              className="phone-btn"
                            >
                              Send OTP
                            </button>
                          </div>
                          <div className="form-group-captcha">
                            <div id="recaptcha-container"></div>
                          </div>
                          <div className="form-group">
                            <span className="form-label">Enter OTP*</span>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              OTPLength={6}
                              otpType="number"
                              disabled={false}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <span className="form-label"></span>
                            <button
                              type="button"
                              onClick={handleVerifyOTP}
                              className="phone-btn"
                            >
                              Verify OTP
                            </button>
                          </div>
                        </div>
                        <div className="form-btn-book-now">
                          <button className="submit-btn" type="submit">
                            Book Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HelmetProvider>
    </>
  );
};

export default BookingForm;